import { Link } from "react-router-dom"
import Header from "./Header"

const NotFound = () => {
    return ( 
        <div className="text-center">
            <Header icon="fa-solid fa-exclamation"/>
            <p className="text-gray-200 font-bold text-5xl">404</p>
            <p className="text-gray-200 mb-8">Page not found</p>
            <Link  to="/" className="bg-sky-500 text-gray-200 rounded px-4 py-2 font-bold">Back to hompage</Link>
        </div>
     );
}
 
export default NotFound;