import React, { useEffect, useState } from 'react'

const IPAddress = () => {
  const [ipAddress, setIPAddress] = useState('')

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);

  return (
      <div className="text-gray-500">Your IP Address is: {ipAddress}</div>
  )
}
export default IPAddress;