import { Link } from "react-router-dom"
import IPAddress from "./IPAddress";

const Footer = () => {
    return ( 
        <footer className="container mx-auto justify-center flex items-center py-12 flex-col">
            <IPAddress />
        </footer>
     );
}
 
export default Footer;