import { Link } from "react-router-dom"
import Header from "./Header";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useState } from "react";
import Loader from "./Loader";
import Banner from "./Banner";
import ReCAPTCHA from "react-google-recaptcha"; //6LdsTJInAAAAAMEmUwgn2ihoygiT-nPIVoh1DgPN
import { faL } from "@fortawesome/free-solid-svg-icons";


const Contact = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [verified, setVerified] = useState(false)
    const [captchaError, setCaptchaError] = useState(false)

    const form = useRef();

    const onChange = (value) => {
        const params = {
            user_name: name,
            user_email: email,
            message: message,
            'g-recaptcha-response': value,
        }
        setVerified(true);
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    }

    const sendEmail = (e) => {
        setIsLoading(true);
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, form.current, process.env.REACT_APP_EMAILJS_PUBLICKEY,)
      .then((result) => {
          setIsLoading(false);
          setIsSent(true);
          setCaptchaError(false)
          resetForm();
      }, (error) => {
          console.log(error.text);
          if (!verified) {
            setCaptchaError(true)
          }
          setIsLoading(false);
      });
  };

    return ( 
        <div className="">
            <div className="text-center max-w-full">
            <Header icon="fa-solid fa-envelope"/>
            </div>
            <form className="max-w-xs md:max-w-full" ref={form} onSubmit={sendEmail}>
            <p className="pb-1 text-gray-300"> Thanks for reaching out! We will respond to you as soon as possible.</p>
            <Link to="/" className="underline text-gray-200">Back to homepage</Link>
            {isSent ? <Banner msg="Email sent!" type="success" /> : ""}
            {captchaError ? <Banner msg="Captcha not valid!" type="warn" /> : ""}
                <div className="flex-col container flex justify-center md:flex-row w-full text-lg mt-5">
                    <div className="formgroup w-full md:w-1/2 md:p-2">
                        <label className="block text-gray-300">Name</label>
                        <input className="w-full px-2 py-1 rounded-md" type="text" name="user_name" required value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="formgroup w-full md:w-1/2 md:p-2">
                        <label className="block text-gray-300" required >Email</label>
                        <input className="w-full px-2 py-1 rounded-md" type="email" name="user_email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="flex-col container flex justify-center text-lg">
                    <div className="formgroup w-full md:p-2">
                        <label className="block text-gray-300 mb-1">Message</label>
                        <textarea required className="w-full px-2 py-1 rounded-md" rows="10" type="text" name="message" value={message} onChange={(e) => setMessage(e.target.value)}/>
                    </div>
                </div>
                <ReCAPTCHA
                    sitekey="6LdsTJInAAAAAMEmUwgn2ihoygiT-nPIVoh1DgPN"
                    onChange={onChange}
                />,
                <button disabled={isLoading} type="submit" className="float-right bg-sky-500 text-gray-200 rounded px-4 py-2 font-bold disabled:opacity-50 mt-2">
                    {isLoading ? <Loader /> : "Send"}
                </button>
            </form>
            
        </div>
     );
}
 
export default Contact;