const Header = () => {
    return ( 
        <div>
            <div className="container flex justify-center mb-2">
                <svg src="/static/img/logo.svg" alt="Perrottet Solutions" max-width={"400px"} width={"30%"}/>
            </div>
            <h1 className="text-2xl text-sky-500 font-bold mb-8 md:text-4xl md:font-extrabold lg:font-extrabold lg:text-7xl">ueberswucheend.ch</h1>
        </div>
     );
}
 
export default Header;