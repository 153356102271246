import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SuccessBanner = (props) => {

    switch (props.type) {
        case "error":
            return ( 
                <div className='text-gray-200 p-2 rounded-md border bg-red-600 border-red-900 mt-5'>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className='pr-2'/>
                    <p className='inline'>{props.msg}</p>
                </div>
             );

        case "warn":
            return ( 
                <div className='text-gray-200 p-2 rounded-md border bg-yellow-600 border-yellow-900 mt-5'>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className='pr-2'/>
                    <p className='inline'>{props.msg}</p>
                </div>
             );

        case "success":
            return ( 
                <div className='text-gray-200 p-2 rounded-md border bg-green-600 border-green-900 mt-5'>
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" className='pr-2' />
                    <p className='inline'>{props.msg}</p>
                </div>
             );

        default:
            return ( 
                <div className='text-gray-200 p-2 rounded-md border bg-blue-600 border-blue-900 mt-5'>
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" className='pr-2' />
                    <p className='inline'>{props.msg}</p>
                </div>
             );
    }
}
 
export default SuccessBanner;