import './App.css';

import Home from './Home';
import NotFound from './NotFound';
import Contact from './Contact';
import Impressum from './Impressum'
import Footer from './Footer';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

function App() {
  return (
    <Router>
      <div className="bg-gray-900 min-h-screen pt-14">
        <div className="container flex justify-center items-center mx-auto">
        <Routes>
          <Route path="/" element={<Home /> } />
          <Route path="/contact" element={<Contact/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
