import { Link } from "react-router-dom"
import Header from "./Header"

const Home = (props) => {
    return ( 
        <div className="text-center">
            <Header/>
            <p className="text-gray-200">Our website is currently under construction</p>
            <p className="text-gray-200 mb-8">For further information, use our contact form!</p>
            <Link  to="/contact" className="bg-sky-500 text-gray-200 rounded px-4 py-2 font-bold">Contact us!</Link>
            <br />
            <br />
            <br />
            <a href="ts3server://ueberswucheend.ch?port=9987" className="text-gray-200 rounded px-4 py-2 font-bold underline">Click here to connect to our teamspeak!</a>
        </div>
         );
}
 
export default Home;